import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { flow } from 'lodash/fp'
import { Constants } from 'modules/Advisory/modules/Copilot/Constants'
import { IProfileApiContext } from 'modules/Advisory/modules/Rdot360/store/profileApi/IProfileApiContext'
import { useCallback } from 'react'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { axiosBaseQuery } from './shared'

export const RCopilotApiSliceKey = 'api.RCopilot'
export type RCopilotApiReducerState = {
  [RCopilotApiSliceKey]: ReturnType<typeof rCopilotApi.reducer>
}

const getApiBaseUrl = (state: AppState) => {
  const base = flow(getRockefellerApiConfig, (x) => x?.root)(state)
  const v1 = new URL('', base)
  return v1.href
}

const getApiAuthToken = (state: AppState) => {
  const scopes = flow(getRockefellerApiConfig, (x) => x?.scopes)(state)
  const msalScopes = Constants.msal.semanticKernelScopes.concat(scopes ?? [])

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(msalScopes)
}

export const rCopilotApi = createApi({
  reducerPath: RCopilotApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getApiBaseUrl(state),
    getAuthToken: (state) => getApiAuthToken(state)
  }),
  endpoints: (builder) => ({
    addToMemory: builder.mutation<any, IMemoryPayload>({
      query: (payload) => {
        return {
          url: `rcopilot/memory`,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          method: 'POST',
          data: payload
        }
      }
    }),
    sendContext: builder.mutation<any, IProfileApiContext>({
      query: (payload) => {
        return {
          url: `rcopilot/contextSession`,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          method: 'POST',
          data: payload
        }
      }
    })
  })
})

export const { useAddToMemoryMutation, useSendContextMutation } = rCopilotApi

export interface IMemoryPayload {
  memories: {
    content: string
    tags: { category: string[] }
  }[]
  location: string
  householdId: string
}

export const useCopilotMemory = () => {
  const [addToMemory] = useAddToMemoryMutation()
  const addObjectToMemory = useCallback(
    (object: any, location: string, tags: string[], householdId: string) => {
      if (!object) {
        return
      }
      const jsonString = JSON.stringify(object)
      if (jsonString === '{}' || jsonString === '[]') {
        return
      }
      addToMemory({
        memories: [
          {
            content: jsonString,
            tags: { category: tags }
          }
        ],
        location,
        householdId
      })
    },
    [addToMemory]
  )
  const [sendContext] = useSendContextMutation()
  const sendContextToCopilot = useCallback(
    (context: IProfileApiContext) => {
      sendContext(context)
    },
    [sendContext]
  )
  return { addObjectToMemory, sendContextToCopilot }
}
