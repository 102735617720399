import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { FC, useEffect, useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { useCopilotMemory } from 'store/api/rcopilot'
import { useHandleViewTypeChange } from '../../hooks/useHandleViewTypeChange'
import { ClientDashboardTiles } from '../../shared/types'
import { useGetTop10HoldingsQueryForSelectedAccounts } from '../../store/rdot360Context/useRdot360HoldingsContext'
import { SnackBar } from '../shared/Snackbar'
import TileHeading, { BaseHeaderProps, ViewType } from '../TileHeading'
import { TileLoadingOverlay } from '../TileLoadingOverlay'
import { HoldingsChart } from './HoldingsChart'
import HoldingsTable from './HoldingsTable'

const tileName = ClientDashboardTiles.holdingsTile

const Holdings: FC<BaseHeaderProps> = ({ onExpand, isCobSupported }) => {
  const { handleViewTypeChange, viewType } = useHandleViewTypeChange(tileName)

  const {
    data: holdingsResponseData,
    isLoading: isHoldingsLoading,
    isFetching: isHoldingsFetching,
    isUninitialized,
    error: isHoldingError
  } = useGetTop10HoldingsQueryForSelectedAccounts()

  const holdingsData = useMemo(
    () =>
      isHoldingsFetching || isHoldingError || !holdingsResponseData
        ? undefined
        : holdingsResponseData,
    [isHoldingsFetching, isHoldingError, holdingsResponseData]
  )
  const { addObjectToMemory } = useCopilotMemory()
  const { selectedHouseholdId } = useRdot360Context()
  useEffect(() => {
    if (isHoldingsFetching || isHoldingError || isUninitialized) {
      return
    }
    addObjectToMemory(
      holdingsData,
      'copilot-client-dashboard',
      ['investments'],
      selectedHouseholdId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addObjectToMemory,
    holdingsData,
    isHoldingError,
    isHoldingsFetching,
    isUninitialized
  ])

  const isError = useMemo(() => !!isHoldingError, [isHoldingError])
  const errorMessage =
    (isHoldingError as Error)?.message || 'An unknown Error Occurred'

  return (
    <>
      <div
        css={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TileHeading
          viewType={viewType}
          onViewtypeChange={handleViewTypeChange}
          graphIconType="chart"
          onExpand={onExpand}
          isCobSupported={isCobSupported}
        >
          INVESTMENTS
        </TileHeading>
        {isError && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar message={errorMessage} type="Failure" />
          </div>
        )}
        <div
          css={{
            display: 'flex',
            marginTop: '5px',
            fontSize: '12px',
            marginBottom: '8px',
            flex: '0 0 14px',
            gridColumnGap: 5,
            rowGap: 4,
            flexWrap: 'wrap'
          }}
        >
          <>
            <div css={{ fontWeight: 300 }}>Total Priced Investments:</div>
            {holdingsData?.totalPosMktVal && holdingsData?.uniqSecCount ? (
              <>
                <USD value={holdingsData?.totalPosMktVal} fractionDigits={0} />{' '}
                ({holdingsData?.uniqSecCount} Holdings )
              </>
            ) : (
              '--'
            )}
            <div css={{ display: 'flex' }}>
              <div css={{ fontWeight: 300, marginRight: '5px' }}>/ Top 10:</div>
              {holdingsData?.recentmktval ? (
                <>
                  <USD value={holdingsData?.recentmktval} fractionDigits={0} />
                </>
              ) : (
                '--'
              )}
            </div>
          </>
        </div>
        <div css={{ flexGrow: 1, minHeight: 0 }}>
          {viewType === ViewType.graph && (
            <HoldingsChart data={holdingsData?.holdingdetails || []} />
          )}
          {viewType === ViewType.table && (
            <HoldingsTable
              data={holdingsData?.holdingdetails}
              isUninitialized={isUninitialized}
              isLoading={isHoldingsFetching}
            />
          )}
        </div>
        <div
          css={{
            fontSize: '10px',
            fontWeight: 300,
            marginTop: 6
          }}
        >
          Top 10 Investments excludes Cash, Annuities/Insurance, Loans and
          select Specialty Assets.
        </div>
      </div>
      {(isHoldingsLoading || isHoldingsFetching) && <TileLoadingOverlay />}
    </>
  )
}

export default Holdings
