import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useGetInsightByHouseholdIdQuery } from 'store/api/insights'
import { useRdot360Context } from '../../../store/rdot360Context'

export const useInsightsByHouseholdId = () => {
  const { selectedHouseholdId } = useRdot360Context()
  const request = !selectedHouseholdId
    ? skipToken
    : { householdIds: [selectedHouseholdId] }
  const { currentData, error, isError, isFetching, refetch, isUninitialized } =
    useGetInsightByHouseholdIdQuery(request)

  return {
    data: isError ? undefined : currentData,
    error,
    isFetching,
    refetch,
    isUninitialized
  }
}
