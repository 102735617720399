import { MessageBar, MessageBarType } from '@fluentui/react'
import { eachMonthOfInterval, format, parseISO } from 'date-fns'
import {
  ITimeSeriesChartData,
  ITimeSeriesChartProps,
  ITimeSeriesChartSeries
} from 'features/Charts/TimeSeriesChart'
import type { SeriesAreaOptions, SeriesColumnOptions } from 'highcharts'
import { groupBy, merge, sumBy, unionBy } from 'lodash'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { memo, useEffect, useMemo } from 'react'
import {
  HighchartsComponent,
  HighchartsImport,
  useHighcharts,
  useHighchartsOptions
} from 'shared/highcharts'
import { useCopilotMemory } from 'store/api/rcopilot'
import { TileLoadingOverlay } from '../../../components/TileLoadingOverlay'
import { useGetRevenueSummaryChartData } from '../../../features/Revenue/useRevenueContext'

const getConfiguration = (
  highcharts: HighchartsImport,
  onDrilldown?: (date: Date, category: string) => void
): Highcharts.Options => ({
  title: {
    text: ''
  },
  yAxis: {
    title: {
      text: ''
    },
    softMin: 0
  },
  legend: {
    enabled: true,
    layout: 'horizontal',
    symbolRadius: 0,
    align: 'center',
    itemWidth: 200,
    verticalAlign: 'bottom',
    backgroundColor: 'transparent',
    floating: false,
    shadow: false
  },
  tooltip: {
    formatter: function formatTooltip(
      this: Highcharts.TooltipFormatterContextObject
    ) {
      return `<b>${format(this.x as number, `MMM ''yy`)}</b><br/>${
        this.series.name
      }: $${highcharts.numberFormat(this.y as number, 0, '.', ',')}
      <br/>TOTAL: $${highcharts.numberFormat(this.total ?? 0, 0, '.', ',')}`
    }
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      pointPadding: 0,
      groupPadding: 0.1,
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      },
      pointWidth: 10,
      events: {
        click: (event: any) => {
          onDrilldown?.(new Date(event.point.x), event.point.series.name)
        }
      }
    },
    area: {
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        }
      }
    ]
  }
})

export const categoryLookup: Record<string, string> = {
  'Educational Savings': '#E25822',
  'Advisory Fees': '#004170',
  'Banking Related': '#E2725B',
  Brokerage: '#fd5c63',
  Other: '#0C2340',
  Uncategorized: '#0C2340',
  Retirement: '#004953',
  'Annuity & Insurance': '#F0E68C',
  'GFO Services': '#BDE1D6',
  'Rockefeller Deals': '#50B58F',
  'Structured Products': '#73C2FB',
  'Alternative Investments': '#5F9EA0',
  '529': '#E2AF97',
  Harvest: '#ADF1FF',
  'Carrick Lane': '#7ABEFB',
  Envestnet: '#004170',
  'BlackRock Held Away': '#145895',
  'Margin Interest': '#EF9F6F',
  Sweep: '#BC6C3C',
  Cash: '#893909',
  NFSC: '#CFC9B0',
  'Foreign Exchange': '#2F491F',
  Units: '#496339',
  Equities: '#00693E',
  'Mutual Funds': '#AFC99F',
  'Fixed Income': '#E32636',
  Options: '#E2FCD2',
  Annuity: '#EDE187',
  Insurance: '#BAAE54',
  'GFO 2020 Services': '#BDE1D6',
  GFO: '#8AAEA3',
  'Titan AG': '#72A0C1',
  'Titan Birds Fund': '#B0C4DE',
  'Titan Space Fund': '#004526',
  VantageRock: '#6ACFA9',
  'TigerGlobal Access': '#50B58F',
  'Lifted Truck': '#A51C30',
  'Supreme Golf': '#1D825C',
  'Other Categories': '#0078D4'
}

const containerProps = {
  style: { height: '100%', width: '100%' }
}
const TimeSeriesChart: React.FC<ITimeSeriesChartProps> = ({
  series,
  chartType,
  options
}) => {
  const weightedSeries = series.map((componentSeries) => {
    const { name, data, stack, color } = componentSeries

    const highChartsSeries: SeriesColumnOptions | SeriesAreaOptions = {
      name,
      data: data
        .filter(({ date }) => date != null)
        .map((item) => [item?.date?.getTime(), item.value]),
      stack: stack || 0,
      type: chartType || 'area',
      color
    }

    return {
      series: highChartsSeries,
      total: data.reduce((a, x) => a + (x?.value || 0), 0)
    }
  })

  const seriesOptions: Highcharts.Options = {
    series: weightedSeries.map(({ series, total }) => ({
      ...series,
      total
    })),
    xAxis: {
      type: 'datetime',
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        format: `{value:%b '%y}`
      }
    }
  }

  const defaultChartOptions = useHighchartsOptions(getConfiguration)
  const mergedChartOptions = merge(
    {},
    defaultChartOptions,
    options,
    seriesOptions
  )

  return (
    <HighchartsComponent
      options={mergedChartOptions}
      containerProps={containerProps}
    />
  )
}

export const RevenueSummaryChart: React.FC<{
  startDate: Date
  endDate: Date
}> = memo(({ startDate, endDate }) => {
  const { data, isFetching, isUninitialized } = useGetRevenueSummaryChartData(
    format(startDate, 'yyyy-MM-dd'),
    format(endDate, 'yyyy-MM-dd')
  )
  const { addObjectToMemory } = useCopilotMemory()
  const { selectedHouseholdId } = useRdot360Context()
  useEffect(() => {
    if (isFetching || isUninitialized) {
      return
    }
    addObjectToMemory(
      data,
      'copilot-client-dashboard',
      ['revenue'],
      selectedHouseholdId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addObjectToMemory, data, isFetching, isUninitialized])
  const highcharts = useHighcharts()
  const configuration = useMemo(
    () => highcharts && getConfiguration(highcharts),
    [highcharts]
  )
  const revenueHistory = data
  const unknownCategory = 'Uncategorized'
  const pivotGroups = groupBy(
    revenueHistory,
    ({ assetType }) => assetType ?? unknownCategory
  )
  const pivotKeys = Object.keys(pivotGroups)

  const series: ITimeSeriesChartSeries[] = pivotKeys.map((pivotKey) => {
    const pivotGroup = pivotGroups[pivotKey]
    const pivotDateGroups = groupBy(pivotGroup, (x) => x.payrollPeriodDate)
    const dateData: ITimeSeriesChartData[] = Object.entries(pivotDateGroups)
      .map(([timestamp, items]) => {
        const value = items.reduce((a, x) => a + x.allocatedAmount, 0)
        return {
          date: new Date(parseISO(timestamp)),
          value
        }
      })
      .filter((x) => x.value !== 0)

    return {
      data: dateData,
      name: pivotKey,
      color: categoryLookup[pivotKey] || categoryLookup[unknownCategory]
    }
  })
  const getSeriesSums = useMemo(() => {
    let seriesSums = series.map((seriesItem) => {
      return {
        name: seriesItem.name,
        color: seriesItem.color,
        data: seriesItem.data,
        sum: sumBy(seriesItem.data, (x) => x.value ?? 0)
      }
    })
    const emptyData = eachMonthOfInterval({
      start: startDate,
      end: endDate
    }).map((date) => {
      return {
        date,
        value: 0
      }
    })
    const emptyDataSeries = seriesSums.map((series) => {
      return {
        value: 0,
        data: emptyData,
        name: series.name,
        color: series.color
      }
    })
    seriesSums = seriesSums.map((series) => {
      return {
        ...series,
        data: unionBy(series.data, emptyData, (x) => x.date?.toDateString())
      }
    })
    const maxCategories = 5
    seriesSums = seriesSums.sort((a, b) => b.sum - a.sum)
    if (seriesSums.length > maxCategories) {
      const extraSeries = seriesSums.slice(maxCategories).flatMap((x) => x.data)
      const dateData = groupBy(extraSeries, (x) => x.date?.toDateString())
      const newSeries = Object.entries(dateData).map(([key, value]) => {
        return {
          date: new Date(key),
          value: sumBy(value, (x) => x.value ?? 0)
        }
      })
      const seriesSumsLength = seriesSums.length
      seriesSums = seriesSums.slice(0, maxCategories)

      seriesSums.push({
        name: `Other Categories (` + (seriesSumsLength - maxCategories) + `)`,
        color: categoryLookup['Other Categories'],
        data: newSeries,
        sum: sumBy(newSeries, (x) => x.value ?? 0)
      })
    }
    return { seriesSums, emptyDataSeries }
  }, [endDate, series, startDate])

  return (
    <>
      {!!series.length && (
        <TimeSeriesChart
          series={
            isFetching
              ? getSeriesSums.emptyDataSeries
              : getSeriesSums.seriesSums
          }
          chartType={'column'}
          options={configuration}
          desc={true}
        />
      )}

      {(data === undefined || data?.length === 0) &&
        !isUninitialized &&
        !isFetching && (
          <MessageBar messageBarType={MessageBarType.info}>
            No data available
          </MessageBar>
        )}
      {isFetching && <TileLoadingOverlay />}
    </>
  )
})
