import { css } from '@emotion/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { IHouseholdInsight } from 'store/api/insights'
import { useCopilotMemory } from 'store/api/rcopilot'
import { SnackBar } from '../../../components/shared/Snackbar'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { TileLoadingOverlay } from '../../../components/TileLoadingOverlay'
import { Icon } from '../../../features/Icons/Icon'
import { InsightDetail } from './InsightDetail'
import { InsightEmpty } from './InsightEmpty'
import { InsightIntro, InsightIntroEmpty } from './InsightIntro'
import { useInsightsByHouseholdId } from './useInsightsByHouseholdId'

const classes = {
  container: css({
    display: 'flex',
    gridColumnGap: 25,
    justifyContent: 'center',
    paddingBottom: '10px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }),
  dot: css({
    borderRadius: '50%',
    backgroundColor: '#D9D9D9',
    cursor: 'pointer',
    display: 'inline-block',
    height: '8px',
    width: '8px'
  }),
  dotContainer: css({
    alignItems: 'center',
    display: 'flex',
    gridColumnGap: 5,
    justifyContent: 'center'
  }),
  dotSelected: css({
    backgroundColor: '#0E687B'
  }),
  loader: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  title: css({
    color: '#06262D'
  })
}

const introInsightName = 'INSIGHTS'

export const Insights: React.FC<BaseHeaderProps> = () => {
  const { data, error, isFetching, refetch, isUninitialized } =
    useInsightsByHouseholdId()
  const { addObjectToMemory } = useCopilotMemory()
  const { selectedHouseholdId } = useRdot360Context()
  useEffect(() => {
    if (isFetching || isUninitialized) {
      return
    }
    addObjectToMemory(
      data,
      'copilot-client-dashboard',
      ['insights'],
      selectedHouseholdId ?? ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addObjectToMemory, data, isFetching, isUninitialized])
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const insightsWithIntro = useMemo(() => {
    if (!data || data.length < 2) {
      return data
    }

    const sortedData = [...data]
    sortedData.sort((a, b) => (a.ins_order ?? 0) - (b.ins_order ?? 0))

    const introInsight: IHouseholdInsight = {
      ins_name: introInsightName,
      ins_order: -1,
      statements: sortedData.flatMap((x) =>
        (x.statements || [])
          .filter((s) => s.type === 'IMT')
          .map((s) => ({
            order: x.ins_order ?? s.order,
            statement: s.statement,
            type: x.ins_name
          }))
      )
    }
    return [introInsight, ...sortedData]
  }, [data])

  const totalInsights = insightsWithIntro?.length ?? 0
  const selectedInsight = insightsWithIntro?.[selectedIndex]
  const shouldDisableNext = selectedIndex >= totalInsights - 1
  const shouldDisablePrevious = selectedIndex <= 0

  const onNextClicked = useCallback(() => {
    if (shouldDisableNext) {
      return
    }
    setSelectedIndex((prev) => prev + 1)
  }, [shouldDisableNext, setSelectedIndex])

  const onPreviousClicked = useCallback(() => {
    if (shouldDisablePrevious) {
      return
    }
    setSelectedIndex((prev) => prev - 1)
  }, [shouldDisablePrevious, setSelectedIndex])

  const SelectedInsight = () => {
    if (isFetching) {
      return <InsightIntroEmpty />
    }

    if (error instanceof Error) {
      return (
        <SnackBar
          message={error.message || 'An unknown Error Occurred'}
          type="Failure"
          actionButtonLabel="click to retry"
          onActionButtonClicked={refetch}
        />
      )
    }

    if (!selectedInsight) {
      return <InsightEmpty />
    }

    if (selectedInsight.ins_name === introInsightName) {
      return (
        <InsightIntro
          insight={selectedInsight}
          onClick={(index) => setSelectedIndex(index + 1)}
        />
      )
    }

    return <InsightDetail insight={selectedInsight} />
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TileHeading showIcons={false}>
        <span css={classes.title}>Insights</span>
      </TileHeading>
      <SelectedInsight />
      {selectedInsight && (
        <div css={classes.container}>
          <div
            css={{
              opacity: shouldDisablePrevious ? 0.5 : 1,
              cursor: shouldDisablePrevious ? 'default' : 'pointer',
              width: 18,
              height: 18
            }}
            onClick={onPreviousClicked}
            title="Previous"
          >
            <Icon type="Previous" color="#0F65BB" />
          </div>
          <div css={classes.dotContainer}>
            {insightsWithIntro?.map((insight, index) => (
              <span
                key={index}
                css={[
                  classes.dot,
                  index === selectedIndex ? classes.dotSelected : null
                ]}
                onClick={() => setSelectedIndex(index)}
                title={insight.ins_name}
              />
            ))}
          </div>
          <div
            css={{
              opacity: shouldDisableNext ? 0.5 : 1,
              cursor: shouldDisableNext ? 'default' : 'pointer',
              width: 18,
              height: 18
            }}
            onClick={onNextClicked}
            title="Next"
          >
            <Icon type="Next" color="#0F65BB" />
          </div>
        </div>
      )}
      {isFetching && <TileLoadingOverlay />}
    </div>
  )
}
