import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { IAccount } from 'api/account.types'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import {
  IGetRevenueSummaryRequest,
  INFSPilotFlag,
  INfsProfile,
  IRevenueSummaryItem
} from 'api/datahub'
import { IHousehold } from 'api/household.types'
import {
  CDMv2Account,
  UpdateAccountPreferenceRequest,
  UpdateNicknameResponse
} from 'api/nickname.types'
import { constructOdataQuery } from 'api/odata'
import { IOdataRequest } from 'api/odata.types'
import { PerformanceData } from 'api/performance.types'
import { subYears, format } from 'date-fns'
import { orderBy, range } from 'lodash'
import pLimit from 'p-limit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { constructQuery, datahubApi } from 'store/api/datahub'
import { arrayCommaParamsSerializer, AxiosBaseArgs } from 'store/api/shared'
import { apiConstants } from './apis'
import { IPerformanceReport, IPerformanceReportLog } from './types'

const { cacheTime } = apiConstants

const formatDateForApi = (date: Date) =>
  format(date, `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`)

type DatahubApiTagType =
  | 'rdot360Context'
  | 'performance'
  | 'pilotFlags'
  | 'nicknames'
  | 'rdot360Revenue'
  | 'performanceReports'
  | 'households'

const datahubApiTags: DatahubApiTagType[] = [
  'rdot360Context',
  'performance',
  'pilotFlags',
  'nicknames',
  'performanceReports',
  'households'
]

export interface IRevenueHistoryItem {
  assetType?: string
  assetType1?: string
  allocatedAmount: number
  payrollPeriodDate: string
  grossPayout: number
}
export interface IRevenueSummaryItems {
  annualizedRevenue: number
  period: string
  t12Revenue: number
  ytdRevenue: number
  periodRevenue?: number
}
export interface IRevenueSummary {
  currentYear: IRevenueSummaryItems
  mostRecentlyClosed: IRevenueSummaryItems
  prior3Months: IRevenueSummaryItems[]
  priorYear: IRevenueSummaryItems
  priorYearPrior3Months: IRevenueSummaryItems[]
}

export interface IRevenueDetailTableItem {
  account: {
    registration: string
    shortName: string
  }
  event: {
    accountNumber: string
    allocatedAmount: number
    contact: string
    description: string
    feeAndCommission: number
    grossPayout: number
    periodTimestamp: string
    revenueCategoryLvl1: string
    revenueCategoryLvl2: string
    tradeOrRevenueDate: string
  }
  household: {
    householdName: string
  }
  party: {
    partyType: string
  }
  recipients: {
    allocatedAmount: number
    grossPayout: number
    recipient: string
    recipientId: string
  }[]
  rep: {
    name: string
    repId: string
  }
}

export interface PilotFlagRequest {
  pilotfeaturename: string
  pilotenabled: boolean
}

export interface IPerformanceReportPayload {
  queryType?: string
  count?: true
  top?: number
  searchFields?: string
  select?: string
  orderby?: string
  filter?: string
}

const datahubWithRdot360Tags = datahubApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})
export const rdot360DatahubApi = datahubWithRdot360Tags.injectEndpoints({
  endpoints: (builder) => ({
    getIndividualsFromHousehold: builder.query<IClient[] | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `householdList/any(x: x/householdId eq '${id}')`,
          $count: true,
          select: [
            'LegalEntityName',
            'householdList',
            'contactdetails/emailid',
            'legalEntityType',
            'Account',
            'LegalEntityID',
            'LegalEntityName',
            'loginDetails'
          ],
          queryType: 'full'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      },
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_householdSearch: builder.query<
      ISearchResult<IHousehold> | undefined,
      string
    >({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IHousehold>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/households',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: `${searchText}`,
          $count: true,
          select: [
            'Account',
            'noOfAccounts',
            'householdKPI',
            'Parties',
            'Advisors',
            'householdName',
            'householdId',
            'id',
            'RockConnectAccBalance'
          ],
          queryType: 'full',
          $top: 5,
          $orderby: [
            'search.score() desc',
            'RockConnectAccBalance desc',
            'householdKPI/AumTotal desc'
          ],
          searchFields: ['householdName', 'Account', 'Parties/LegalEntityName']
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime,
      providesTags: ['households']
    }),
    rdot360_clientSearch: builder.query<
      ISearchResult<IClient> | undefined,
      string
    >({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: `${searchText}`,
          $count: true,
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'LegalEntityName',
            'LegalEntityID',
            'legalEntityType',
            'ClientKPI/AumTotal',
            'loginDetails',
            'Account',
            'srcClientNumber',
            'householdList',
            'id',
            'RockConnectAccBalance',
            'householdId'
          ],
          queryType: 'full',
          $top: 5,
          $orderby: [
            'search.score() desc',
            'RockConnectAccBalance desc',
            'ClientKPI/AumTotal desc'
          ],
          searchFields: [
            'LegalEntityName',
            'srcClientNumber',
            'Account',
            'householdName'
          ]
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    rdot360_accountSearch: builder.query<
      ISearchResult<IAccount> | undefined,
      string
    >({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IAccount>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/accounts',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: `${searchText}`,
          $count: true,
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'CustodyAccount',
            'LegalEntityName',
            'LegalEntityID',
            'AccountKPIs/AccountTotal',
            'AdvisorAddedNickName',
            'Shortname',
            'CustodianType',
            'CustodianName',
            'registrationtype',
            'gfoCustodyAccount',
            'id',
            'householdId',
            'registrationDesc',
            'RDOTAccountCategoryCode',
            'RDOTAccountCategoryName',
            'RockConnectAccBalance'
          ],
          queryType: 'full',
          $top: 5,
          $orderby: [
            'search.score() desc',
            'RockConnectAccBalance desc',
            'AccountKPIs/AccountTotal desc'
          ],
          searchFields: [
            'CustodyAccount',
            'AdvisorAddedNickName',
            'LegalEntityName',
            'householdName'
          ]
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getHouseholdFromId: builder.query<IHousehold | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/households',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `householdId eq '${id}'`,
          $count: true,
          select: [
            'noOfAccounts',
            'householdKPI',
            'Parties',
            'Advisors',
            'householdName',
            'householdId',
            'id'
          ],
          queryType: 'full',
          $top: 1
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value?.[0]
        }
      },
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime
    }),
    getAccountsFromHouseholdId: builder.query<IAccount[] | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IAccount>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/accounts',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `householdId eq '${id}'`,
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'CustodyAccount',
            'LegalEntityName',
            'LegalEntityID',
            'AccountKPIs/AccountTotal',
            'AdvisorAddedNickName',
            'Shortname',
            'CustodianType',
            'CustodianName',
            'CustodianCode',
            'registrationtype',
            'gfoCustodyAccount',
            'id',
            'householdId',
            'accountStatus',
            'CustodyAccount',
            'legalEntityType',
            'taxstatus',
            'accounttype',
            'registrationDesc',
            'accountId',
            'accountkey',
            'RelatedParties',
            'marginAgreement',
            'multipleMargin',
            'RDOTAccountCategoryCode',
            'RDOTAccountCategoryName'
          ],
          queryType: 'full',
          $orderby: 'id desc'
        }

        const peekTop = 1000
        const chunkTop = 200

        const peek = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams, $top: peekTop, $count: true }
        })) as Response

        const count = peek.data?.['@odata.count'] || 0

        const numChunks = Math.ceil(Math.max(count - peekTop, 0) / chunkTop)
        const requests = range(0, numChunks).map(
          (i): [number, AxiosBaseArgs] => {
            const $skip = peekTop + i * chunkTop
            return [
              i,
              {
                ...baseApiArgs,
                params: {
                  ...baseApiParams,
                  $top: Math.min(chunkTop, count - $skip),
                  $skip
                }
              }
            ]
          }
        )

        const limit = pLimit(5)
        const results = await Promise.all(
          requests.map(([i, x]) =>
            limit(async (): Promise<[number, Response]> => {
              const result = (await baseQuery(x)) as Response
              if (result.error) {
                limit.clearQueue()
              }
              return [i, result]
            })
          )
        )

        const error = results.find(([, result]) => result.error)
        if (error) {
          return { error }
        }

        return {
          data: [
            peek.data?.value || [],
            ...orderBy(results, ([index]) => index, 'asc').map(
              ([, result]) => result.data?.value || []
            )
          ].flat()
        }
      },
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_getMonthlySummary: builder.query<
      IRevenueSummaryItem[] | undefined,
      IGetRevenueSummaryRequest
    >({
      query: ({
        tradeReps,
        recipientReps,
        catMode = 'L1',
        toDate = new Date(),
        fromDate = subYears(new Date(), 1)
      }) => ({
        url: 'revenue/GetMonthlySummary',
        method: 'post',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        },
        data: {
          tradeReps,
          recipientReps,
          CatMode: catMode,
          todate: formatDateForApi(toDate),
          fromdate: formatDateForApi(fromDate)
        }
      }),
      keepUnusedDataFor: cacheTime
    }),
    getRevenueByCategorySummary: builder.query<
      { assetType: string; allocatedAmount: number }[] | undefined,
      { accounts: string[]; startDate: string; endDate: string }
    >({
      query: ({ accounts, startDate, endDate }) => ({
        url: 'RevenueByAccount/GetRevenueByCategorySummary',
        method: 'post',
        data: {
          request: {
            accounts,
            categoryType: 'summary',
            startDate,
            endDate
          }
        }
      }),
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (
        x: { assetType: string; allocatedAmount: number }[]
      ) => x
    }),
    getRevenueHistoryByAccount: builder.query<
      IRevenueHistoryItem[] | undefined,
      {
        accounts: string[]
        startDate: string
        endDate: string
        categoryType: string
      }
    >({
      query: ({ accounts, startDate, endDate, categoryType }) => ({
        url: 'RevenueByAccount/GetRevenueByCategoryHistory',
        method: 'post',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        },
        data: {
          request: {
            accounts,
            startDate,
            endDate,
            categoryType
          }
        }
      }),
      providesTags: ['rdot360Context', 'rdot360Revenue'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IRevenueHistoryItem[]) => x
    }),
    getRevenueDetailedSummaryByAccount: builder.query<
      IRevenueSummary | undefined,
      string[]
    >({
      query: (accounts) => ({
        url: '/RevenueByAccount/GetRevenuePeriodSummary',
        method: 'post',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        },
        data: {
          request: {
            accounts
          }
        }
      }),
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IRevenueSummary) => x
    }),
    getRevenueDetailsTable: builder.query<
      IRevenueDetailTableItem[] | undefined,
      {
        accounts: string[]
        startDate: string
        endDate: string
      }
    >({
      queryFn: async (
        { accounts, startDate, endDate },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        type Response = QueryReturnValue<
          IOdataResult<IRevenueDetailTableItem>,
          Error
        >

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/creditevents',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          queryType: 'full',
          select: [
            'event/periodTimestamp',
            'event/tradeOrRevenueDate',
            'event/accountNumber',
            'account/registrationType',
            'account/shortName',
            'event/contact',
            'party/partyType',
            'event/description',
            'event/feeAndCommission',
            'event/allocatedAmount',
            'recipients',
            'rep/repId',
            'event/revenueCategoryLvl1',
            'event/revenueCategoryLvl2'
          ],
          $orderby: 'event/periodTimestamp desc,id desc',
          $filter: `(event/periodTimestamp ge ${startDate}Z and event/periodTimestamp le ${endDate}Z) and (search.in(event/accountNumber, '${accounts.join(
            '|'
          )}', '|'))`
        }

        const peekTop = 50
        const chunkTop = 200

        const peek = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams, $top: peekTop, $count: true }
        })) as Response

        const count = peek.data?.['@odata.count'] || 0

        const numChunks = Math.ceil(Math.max(count - peekTop, 0) / chunkTop)
        const requests = range(0, numChunks).map(
          (i): [number, AxiosBaseArgs] => {
            const $skip = peekTop + i * chunkTop
            return [
              i,
              {
                ...baseApiArgs,
                params: {
                  ...baseApiParams,
                  $top: Math.min(chunkTop, count - $skip),
                  $skip
                }
              }
            ]
          }
        )

        const limit = pLimit(5)
        const results = await Promise.all(
          requests.map(([i, x]) =>
            limit(async (): Promise<[number, Response]> => {
              const result = (await baseQuery(x)) as Response
              if (result.error) {
                limit.clearQueue()
              }
              return [i, result]
            })
          )
        )
        const error = results.find(([, result]) => result.error)
        if (error) {
          return { error }
        }

        return {
          data: [
            peek.data?.value || [],
            ...orderBy(results, ([index]) => index, 'asc').map(
              ([, result]) => result.data?.value || []
            )
          ].flat()
        }
      },
      providesTags: ['rdot360Context', 'rdot360Revenue'],
      keepUnusedDataFor: cacheTime
    }),
    getNFSProfile: builder.query<INfsProfile | undefined, string>({
      query: (id) => ({
        url: `nfsProfiles/${id}`,
        params: {
          $select: [
            'accounts',
            'wsportaluserid',
            'loginid',
            'id',
            'appVersion'
          ].join(',')
        },
        method: 'get'
      }),
      providesTags: ['rdot360Context'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IOdataResult<INfsProfile>) => x?.value?.[0]
    }),
    rdot360_getPerformance: builder.query<
      PerformanceData[] | undefined,
      string
    >({
      query: (householdId) => ({
        url: `search/performance?$filter=HouseholdId eq '${householdId}'&$count=true&$top=1000&$select=HouseholdName,Name,EntityType,Account,MTDCPerformance,MTDMPerformance,MTDUMPerformance,YTDCPerformance,QTDCPerformance,ITDCPerformance,LastUpdatedAt,ITDMPerformance,ITDUMPerformance,QTDMPerformance,QTDUMPerformance,YTDMPerformance,YTDUMPerformance,override99`,
        method: 'get'
      }),
      providesTags: ['rdot360Context', 'performance'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IOdataResult<PerformanceData>) => x?.value
    }),
    getPilotFlags: builder.query<INFSPilotFlag[] | undefined, number>({
      query: (id) => ({
        url: `nfsProfiles/${id}/pilotflags()`,
        method: 'get'
      }),
      providesTags: ['rdot360Context', 'pilotFlags'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IOdataResult<INFSPilotFlag>) => x?.value
    }),
    setPilotFlag: builder.mutation<
      undefined,
      { id: number; request: PilotFlagRequest }
    >({
      query: ({ id, request }) => ({
        url: `nfsProfiles/${id}/setpilotflag`,
        method: 'POST',
        data: { request }
      }),
      invalidatesTags: ['pilotFlags']
    }),
    rdot360_getAccountNicknames: builder.query<
      CDMv2Account[] | undefined,
      string
    >({
      query: (householdId) => ({
        url: 'Accounts',
        params: {
          $expand: [
            'accountnicknames($select=clientAddedNickName,advisorAddedNickName,sendToPerformance)',
            `addressMappings($filter=addressType eq 'ST';$select=address;$expand=address)`,
            'stakeholders($expand=party($select=partyName,partyType,IRSNo),stakeholderType($select=name,code),stakeholderRelationship($select=name,code),accountRelationship($select=name,code))'
          ],
          $filter: `household/householdId eq '${householdId}'`,
          select: [
            'accountID',
            'accountNumber',
            'shortName',
            'registrationType',
            'registeredRep',
            'establishDate',
            'accountNicknames',
            'partyID'
          ]
        },
        paramsSerializer: arrayCommaParamsSerializer,
        method: 'get'
      }),
      providesTags: ['rdot360Context', 'nicknames'],
      keepUnusedDataFor: cacheTime,
      transformResponse: (x: IOdataResult<CDMv2Account>) => x?.value
    }),
    rdot360_updateAdvisorNickname: builder.mutation<
      {
        responses?: UpdateNicknameResponse[]
      },
      UpdateAccountPreferenceRequest[]
    >({
      query: (items) => ({
        url: '$batch',
        method: 'POST',
        data: {
          requests: items.map((item, i) => ({
            id: (i + 1).toString(),
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: {
              advisorAddedNickName:
                item.advisorNickname === null
                  ? null
                  : item.advisorNickname?.trim(),
              clientAddedNickName:
                item.clientNickname === null
                  ? null
                  : item.clientNickname?.trim(),
              sendToPerformance: item.sendToPerformance
            },
            url: `/AccountNicknames/${item.id}`
          }))
        }
      }),
      invalidatesTags: ['nicknames']
    }),
    rdot360_getMonthEndDates: builder.query<string[] | undefined, undefined>({
      query: () => ({
        url: `holdings/GetMonthEndDates`,
        method: 'get'
      }),
      keepUnusedDataFor: cacheTime
    }),
    getPerformanceReports: builder.query<
      IPerformanceReport[],
      IPerformanceReportPayload | undefined
    >({
      query: (payload: IPerformanceReportPayload) => ({
        url: 'search/genreport',
        method: 'post',
        data: payload
      }),
      keepUnusedDataFor: 60 * 60 * 2,
      transformResponse: (x: IOdataResult<IPerformanceReport>) => x.value || [],
      providesTags: ['rdot360Context', 'performanceReports'] as const
    }),
    getPerformanceReportsLogs: builder.query<
      IPerformanceReportLog[] | undefined,
      IOdataRequest
    >({
      query: (req) => ({
        url: 'performanceReportLogs?' + constructOdataQuery(req)
      }),
      transformResponse: (response: IOdataResult<IPerformanceReportLog>) =>
        response?.value,
      providesTags: () => [{ type: 'performancelogs' }]
    }),
    updateStatusInReportIndex: builder.mutation<
      void,
      {
        id?: string
        sharedIndicator?: 'Yes' | 'No'
      }
    >({
      query: (data) => ({
        url: `performance/UpdateReportSharingStatusInIndex`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['performancelogs']
    })
  })
})

export const {
  useGetIndividualsFromHouseholdQuery,
  useRdot360_householdSearchQuery,
  useRdot360_accountSearchQuery,
  useRdot360_clientSearchQuery,
  useGetHouseholdFromIdQuery,
  useLazyGetAccountsFromHouseholdIdQuery,
  useGetAccountsFromHouseholdIdQuery,
  useRdot360_getMonthlySummaryQuery,
  useGetRevenueByCategorySummaryQuery,
  useGetRevenueHistoryByAccountQuery,
  useLazyGetRevenueHistoryByAccountQuery,
  useGetRevenueDetailedSummaryByAccountQuery,
  useGetRevenueDetailsTableQuery,
  useLazyGetNFSProfileQuery,
  useGetNFSProfileQuery,
  useRdot360_getPerformanceQuery,
  useLazyRdot360_getPerformanceQuery,
  useGetPilotFlagsQuery,
  useSetPilotFlagMutation,
  useRdot360_getAccountNicknamesQuery,
  useRdot360_updateAdvisorNicknameMutation,
  useRdot360_getMonthEndDatesQuery,
  useGetPerformanceReportsQuery,
  useGetPerformanceReportsLogsQuery,
  useUpdateStatusInReportIndexMutation
} = rdot360DatahubApi

export const useDatahubApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<DatahubApiTagType>[]) =>
      dispatch(rdot360DatahubApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}

export const useUpdatePerformanceReportsQueryData = () => {
  const dispatch = useDispatch()
  const updatePerformanceReports = (
    payload?: IPerformanceReportPayload,
    fileId?: string
  ) => {
    dispatch(
      rdot360DatahubApi.util.updateQueryData(
        'getPerformanceReports',
        payload,
        (data) => {
          const index = data.findIndex((item) => item.fileId === fileId)
          if (index !== -1 && data[index].sharedIndicator === 'No') {
            data[index] = { ...data[index], sharedIndicator: 'Yes' }
          }
        }
      ) as unknown as AnyAction
    )
  }

  return updatePerformanceReports
}
